.CardsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    height: 400px;
    margin-top: 150px;
}

.Cards {
    background-color: rgb(190, 182, 182);
    border: 1px solid;
    border-radius: 3px;
    border-color: black;
    width: 98px;
    height: 98px;
}

.Disappeared {
    background-color: white;
    border-radius: 3px;
    border: 1px solid;
    border-color: black;
    width: 98px;
    height: 98px;
}
